import { Card } from "primereact/card";
import i18n from "../../i18n";

const Directions = () => {
  return (
    <Card
      title={i18n.t("contacts.directions")}
      style={{backgroundColor: '#ffffff'}}
    >
      <div>
        <span>{i18n.t("contacts.address")}: Rua Dr. Alberto Tavares de Castro, Nº 26-B, R/C Centro 3770-205 Oliveira do Bairro</span>
      </div>
      <div>
        <iframe title="Fisbairro Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.21768356555!2d-8.497729884240213!3d40.514679379353765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd23a7995da25e9d%3A0xf24e73d4edb9180a!2sFisbairro%20-%20Contabilidade%20e%20Fiscalidade%2C%20Lda.!5e0!3m2!1spt-PT!2spt!4v1622827601512!5m2!1spt-PT!2spt" width="600" height="450" loading="lazy"></iframe>
      </div>
    </Card>
  );
};

export default Directions;
