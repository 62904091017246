import { Card } from "primereact/card";
import logo_noBackground from "../../assets/logo_noBackground.png";
import i18n from "../../i18n";
import "./About.css";

const ResponsiveImage = ( { src, width, height } ) => {
  return (
    <div
      style={ { 
        width,
      } }
      className="responsive-image">
      <div style={ {
          paddingBottom: ( height / width * 100 ) + '%'
        } } />
      <img
        src={ src }
        className="responsive-image__image"
        alt="Fisbairro"
      />
    </div>
  );
}

const whatWeDoOptions = [
  {
    code: "wwdOpt1",
  },
  {
    code: "wwdOpt2",
  },
  {
    code: "wwdOpt3",
  },
  {
    code: "wwdOpt4",
  },
  {
    code: "wwdOpt5",
  },
  {
    code: "wwdOpt6",
  },
  {
    code: "wwdOpt7",
  }
];

const valueOptions = [
  {
    code: "valOpt1",
  },
  {
    code: "valOpt2",
  },
  {
    code: "valOpt3",
  },
  {
    code: "valOpt4",
  },
  {
    code: "valOpt5",
  },
  {
    code: "valOpt6",
  }
];

const About = () => {
  return (
    <div className="about-content">
      <div className="p-d-flex p-jc-center">
        {/* <div>
          <img src={logo} alt="Fisbairro" />
        </div> */}
        <ResponsiveImage
          src={logo_noBackground}
          width={ 2048 }
          height={ 563 }
        />    
      </div>
      <div className="p-d-flex p-jc-center">
        <Card
          title={i18n.t("about.aboutUs")}
          className="p-mx-2 p-my-4 p-my-4"
          style={{backgroundColor: '#ffffff'}}
        >
          <div className="p-text-justify">{i18n.t("about.aboutText")}</div>
        </Card>
      </div>
      {/* What we do */}
      <div className="p-d-flex p-jc-center">
        <Card
          title={i18n.t("about.whatWeDo")}
          className="p-mx-2 p-my-4 p-my-4"
          style={{ width: '100%', backgroundColor: '#ffffff'}}
        >
          <ul>
            {whatWeDoOptions.map((opt) => 
              <li key={opt.code}>
                {i18n.t(`about.whatWeDoOptions.${opt.code}`)}                
              </li> 
            )}
          </ul>
        </Card>
      </div>
      {/* Value/Mission/Visions*/}
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-4">
          <Card
            title={i18n.t("about.mission")} 
            className="p-mx-2 p-my-4 p-my-4"
            style={{minHeight: '380px', backgroundColor: '#ffffff'}}
            header={<i className="pi pi-bookmark p-m-3"
            style={{'fontSize': '3em'}}></i>}
          >           
            <div className="p-text-justify">{i18n.t("about.missionText")}</div>
          </Card>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <Card 
            title={i18n.t("about.vision")}
            className="p-mx-2 p-my-4 p-my-4"
            style={{minHeight: '380px', backgroundColor: '#ffffff'}}
            header={<i className="pi pi-eye p-m-3"
            style={{'fontSize': '3em'}}></i>}
          >
            <div className="p-text-justify p-pb-3">{i18n.t("about.visionText1")}</div>
            <div className="p-text-justify">{i18n.t("about.visionText2")}</div>
          </Card>
        </div>
        <div className="p-col-12 p-md-6 p-lg-4">
          <Card 
            title={i18n.t("about.values")}
            className="p-mx-2 p-my-4 p-my-4"
            style={{minHeight: '380px', backgroundColor: '#ffffff'}}
            header={<i className="pi pi-tag p-m-3"
            style={{'fontSize': '3em'}}></i>}
          >
            {i18n.t("about.valuesText")}
            <ul>
            {valueOptions.map((opt) => 
              <li key={opt.code}>
                {i18n.t(`about.valuesOptions.${opt.code}`)}                
              </li> 
            )}
          </ul>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default About;
