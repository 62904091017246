import { Card } from "primereact/card";
import i18n from "../../i18n";

import Directions from "../diretions/Directions";
import Schedule from "../schedule/Schedule";
import "./Contacts.css";

const Contacts = () => {
  return (
    <div className="contacts-content p-pt-4">
      <div className="p-grid">      
        <div className="p-col-12 p-md-6 p-lg-6 p-pl-3 p-pr-3">
          <Card 
            title={i18n.t("contacts.contacts")}
            style={{minHeight: '420px', backgroundColor: '#ffffff'}}
          >
            <div className="p-pb-2">
              <i className="pi pi-phone"></i>
              <span> - 234 738 078 </span>
              <span>{i18n.t("contacts.fixedCall")}</span>
            </div>
            <div className="p-pb-2">
              <i className="pi pi-envelope"></i>
              <span> - <a href="mailto:geral@fisbairro.pt">geral@fisbairro.pt</a></span>
            </div>
          </Card>
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-pl-3 p-pr-3">
          <Schedule />
        </div>
      </div>
      <div className="p-mx-2 p-my-2">
        <Directions />
      </div>
    </div>
  );
};

export default Contacts;
