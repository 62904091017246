import { Card } from "primereact/card";
import i18n from "../../i18n";
import { Badge } from 'primereact/badge';

const Schedule = () => {
  return <Card
      title={i18n.t("contacts.schedule")}
      style={{minHeight: '420px', backgroundColor: '#ffffff'}}
    >
    {/* <i className="pi pi-info-circle"></i> */}
    <ul>
      <li className="p-pb-2">{i18n.t("week.monday")}: 08:30 - 18:00 <Badge className="p-ml-2" value="*" severity="warning"></Badge></li>
      <li className="p-pb-2">{i18n.t("week.tuesday")}: 08:30 - 18:00 <Badge className="p-ml-2"value="*" severity="warning"></Badge></li>
      <li className="p-pb-2">{i18n.t("week.wednesday")}:  08:30 - 18:00 <Badge className="p-ml-2" value="*" severity="warning"></Badge></li>
      <li className="p-pb-2">{i18n.t("week.thursday")}: 08:30 - 18:00 <Badge className="p-ml-2" value="*" severity="warning"></Badge></li>
      <li className="p-pb-2">{i18n.t("week.friday")}:  08:30 - 18:00 <Badge className="p-ml-2" value="*" severity="warning"></Badge></li>
      <li className="p-pb-2">{i18n.t("week.saturday")}: {i18n.t("week.closed")}</li>
      <li className="p-pb-2">{i18n.t("week.sunday")}: {i18n.t("week.closed")}</li>
    </ul>
    <span><Badge className="p-mr-2" value="*" severity="warning"></Badge> {i18n.t("week.lunch-time")}</span>
  </Card>;
};

export default Schedule;
