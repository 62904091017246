import React from "react";
import { useHistory } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import ptFlag from "../../assets/ptFlag.svg";
import enFlag from "../../assets/enFlag.svg";
import logo from "../../assets/_logo_no_bkg.png";

import i18n from "../../i18n";
import "./Header.css";

const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
};

const rightContents = (
  <React.Fragment>
    <img
      src={ptFlag}
      alt="PT"
      className="countryFlags p-mr-2 p-d-inline"
      onClick={() => changeLanguage("pt")}
    />
    <img
      src={enFlag}
      alt="EN"
      className="countryFlags p-d-inline"
      onClick={() => changeLanguage("en")}
    />
  </React.Fragment>
);

const Header = () => {
  const history = useHistory();
  const changeTab = (e) => {
    history.push(e);
  };

  const logo_content = (
    <img alt="logo" src={logo} height="40" className="p-mr-2"></img>
  );

  const items = [
    {
      label: "",
      icon: "pi pi-fw pi-home",
      link: "/",
      command: () => {
        changeTab("/");
      },
    },
    {
      label: i18n.t("header.contacts"),
      icon: "pi pi-fw pi-phone",
      link: "/contacts",
      command: () => {
        changeTab("/contacts");
      },
    },
  ];

  return (
    <div className="card">
      <Menubar model={items} start={logo_content} end={rightContents} />
    </div>
  );
};

export default Header;
