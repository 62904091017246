import "./Footer.css";
import { Button } from 'primereact/button';
import book from "../../assets/livro_de_reclamos.png";

const Footer = () => {
  return (
    <div className="footer card">
      <footer className="p-d-flex p-jc-between">
        <div>
        <div className="p-text-bold">
          Copyright © Fisbairro
        </div>
        <div className="p-text-bold">
          Contabilidade e Fiscalidade, Lda 2021
        </div>
        </div>
        <div className="template">
          <a href="https://www.facebook.com/Fisbairro" target="_blank" rel="noreferrer">
            {/* <i className="pi pi-facebook"></i> */}
            <Button className="facebook p-p-0">
              <i className="pi pi-facebook p-px-2"></i>
              <span className="p-px-3">Facebook</span>
            </Button>
          </a>
        </div>

        <a href="https://www.livroreclamacoes.pt/" target="_blank" rel="noreferrer">
          {/* <span>Livro de reclamações</span> */}
          <img alt="Livro de reclamações" src={book} className="p-mr-2"></img>
        </a>
      </footer>
    </div>
  );
};

export default Footer;
