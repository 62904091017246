import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TranslationsEN from './translations/en-US.json';
import TranslationsPT from './translations/pt-PT.json';

const resources = {
  en: {
    translation: TranslationsEN,
  },
  pt: {
    translation: TranslationsPT,
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pt',
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
