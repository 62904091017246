import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "./App.css";
import 'primeflex/primeflex.css';
// i18
import { withTranslation } from 'react-i18next';

//Other Componentes
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import About from "./components/about/About";
import Contacts from "./components/contacts/Contacts";
// import Schedule from "./components/schedule/Schedule";
// import Directions from "./components/diretions/Directions";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/contacts">
            <Contacts />
          </Route>
          <Route path="/">
            <About />
          </Route>
        </Switch>
        <Footer className="footer"/>
      </div>      
    </Router>
  );
}

export default withTranslation()(App);
